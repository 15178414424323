import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Banner.css';
import LazyLoadImage from '../LazyLoadImage'; // Import the LazyLoadImage component

const Banner = ({ banners, loading }) => {
    const settings = {
        items: 1,
        margin: 10,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        dots: true,
        nav: false,
    };
    const defaultImage = 'https://via.placeholder.com/150';

    const navigate = useNavigate();

    const handleBannerClick = (type_id, tag_type) => {
        navigate(`/latest-arrivals/${type_id}/${tag_type}`);
    };

    return (
        <section className="banner">
            <OwlCarousel className="banner-carousel owl-carousel owl-theme" {...settings}>
                {loading ? (
                    // Skeleton Loader for Banners
                    [...Array(3)].map((_, index) => (
                        <div key={index} className="item">
                            <Skeleton height={800} count={3} />
                        </div>
                    ))
                ) : (
                    // Actual Banner Content
                    banners.length > 0 ? (
                        banners.map((banner, index) => (
                            <div
                                key={index}
                                className="item"
                               // onClick={() => handleBannerClick(banner.type_id, banner.type)}
                            >
                                <Link to="#" className="banner-btn">
                                    <LazyLoadImage
                                        thumbnailSrc={banner.media_thumbnail}
                                        fullImageSrc={banner.media_url || defaultImage}
                                        alt={banner.heading_1 || 'Banner Image'}
                                        onError={(e) => (e.target.src = defaultImage)}
                                    />
                                </Link>
                            </div>
                        ))
                    ) : (
                        <div className="item">
                            <p>No banners available</p>
                        </div>
                    )
                )}
            </OwlCarousel>
        </section>
    );
};

export default Banner;
