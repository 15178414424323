import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './LiveSocial.css';
import Modal from '../modal/Modal';

const LiveSocial = ({ videos, loading }) => {
    const [selectedVideo, setSelectedVideo] = useState(null);

    const openModal = (videoUrl) => setSelectedVideo(videoUrl);
    const closeModal = () => setSelectedVideo(null);

    const settings = {
        items: 5,
        margin: 10,
        loop: true,
        autoplay: false,
        autoplayTimeout: 4000,
        dots: true,
        responsive: {
            0: { items: 2 },
            768: { items: 3 },
            1024: { items: 4 },
            1200: { items: 5 },
        },
    };

    return (
        <section className="live">
            <div className="container">
                {loading ? (
                    <>
                        <h2>
                            <Skeleton width={150} height={30} />
                        </h2>
                        {/* <OwlCarousel className="social-carousel owl-carousel owl-theme" {...settings}> */}
                        <div className="social-carousel">

                            {[...Array(4)].map((_, index) => (
                                <div key={index} className="item">
                                    <div className="img-box">
                                        <Skeleton height={563} />
                                    </div>
                                    <div className="content">
                                        <Skeleton />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* </OwlCarousel> */}
                    </>
                ) : (
                    <>
                        <h2>
                            {videos.heading}
                            {/* <Link to="/live-social" className="read-more"></Link> */}
                        </h2>
                        <div className="social-carousel">

                            {videos.data.carousel.map((item, index) => (
                                <div key={index} className="item">
                                    <div className="img-box">
                                        <img
                                            src={item.video_thumbnail_web}
                                            alt={`Social Image ${index + 1}`}
                                            onClick={() => openModal(item.video_url)}
                                        />
                                    </div>
                                    <div className="content">

                                        <h3> <Link 
                                        // to={`/visit-store/${item.store_uuid}`}
                                        >{item.store_name} </Link></h3>
                                        <h4>{item.video_description}</h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                <Modal
                    isOpen={!!selectedVideo}
                    onClose={closeModal}
                    videoUrl={selectedVideo} // Pass the video URL to the Modal
                />
            </div>
        </section>
    );
};

export default LiveSocial;
