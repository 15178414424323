import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import api from '../../api';
import { useAuth } from '../../context/AuthContext'; 
import { useCurrency } from '../../context/CurrencyContext';
import { useCart } from '../../context/CartContext';

import Banner from './Banner';
import LiveSocial from './LiveSocial';
import WeddingStore from './WeddingStore';
import LatestArrivals from './LatestArrivals';
import VisitStoreDigitally from './VisitStoreDigitally';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const CACHE_KEY = 'homePageData';
const CACHE_DURATION = 3600000;

const HomePage = () => {
    const { logout } = useAuth();
    const { setCurrencyPreference } = useCurrency();
    const navigate = useNavigate();

    const { setCartCount } = useCart();
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const clearHomePageCache = () => {
        localStorage.removeItem(CACHE_KEY);
        localStorage.removeItem(`${CACHE_KEY}_timestamp`);
    };
    

    useEffect(() => {
        let api_token = localStorage.getItem('apiToken');
        const fetchData = async () => {
            setLoading(true);
            try {
                const clientId = localStorage.getItem('clientId');
                const userRole = localStorage.getItem('userRole');

                if (userRole === 'guest' && !api_token.startsWith('guest_')) {
                    api_token = 'guest_' + api_token;
                }

                const response = await api.post('/user/user_home_web', {
                    api_token:'guest_c3dd6acbb410413a8473953f2dadce69',
                    app_type: 'web',
                    app_version: '1.0',
                    country_code: 'US',
                    advertiser_id: '',
                    device_token: clientId,
                });

                if (response.data.status === 200) {
                    const cartResponse = await api.post('/cart/create_cart', {
                        api_token
                    });

                    if (cartResponse.data && cartResponse.data.cart_data) {
                        const { cart_id, order_num, item_count } = cartResponse.data.cart_data;

                        // Save cart data to localStorage
                        localStorage.setItem('cart_id', cart_id);
                        localStorage.setItem('order_num', order_num);
                        localStorage.setItem('item_count', item_count);
                        localStorage.setItem('cartCount', item_count);

                        // Update cart count
                        if (typeof item_count === 'number' && !isNaN(item_count)) {
                            setCartCount(item_count);
                        } else {
                            console.error('Invalid item_count:', item_count);
                        }

                    } else {
                        console.error('Cart data is missing:', cartResponse.data);
                    }

                    setData(response.data);
                  
                } else if (response.data.status === 401) {
                    logout();
                  
                    navigate('/home');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        if(api_token!=null)
            {
        fetchData();
            }
    }, [logout, setCartCount]);

    if (loading) {
        return (
            <div className="main-content" id="blur-div">
                <Skeleton height={800} />
                <Skeleton height={563} />
                <Skeleton height={800} />
                <Skeleton height={563} />
                <Skeleton height={800} />
            </div>
        );
    }

    return (
        <div className="main-content" >
            {data.slider_1 && <Banner banners={data.slider_1} />}
            {data.live_social && <LiveSocial videos={data.live_social} />}
            {data.slider_2 && <WeddingStore bannerImage={data.slider_2} />}
            {data.latest_arrival && <LatestArrivals products={data.latest_arrival} digital_stores={data.digital_stores} />}
            {data.digital_stores && <VisitStoreDigitally digital_stores={data.digital_stores} stores='' />}
        </div>
    );
};

export default HomePage;
